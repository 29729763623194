import { render, staticRenderFns } from "./Video.vue?vue&type=template&id=3ea8ca62&scoped=true&"
import script from "./Video.vue?vue&type=script&lang=js&"
export * from "./Video.vue?vue&type=script&lang=js&"
import style0 from "./Video.vue?vue&type=style&index=0&id=3ea8ca62&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ea8ca62",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Video: require('/vercel/path0/components/elements/Video.vue').default})
