
import getHomeHeroVideos from '~/queries/getHomeHeroVideos';

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      mobileSrc: null,
      desktopSrc: null,
      isMobile: true,
      sourceSrc: ''
    };
  },
  // async created() {
  //   if (this.data.desktopVideo && this.data.mobileVideo) {
  //     const query = getHomeHeroVideos({
  //       mobileRef: this.data?.mobileVideo?.refVideo?._ref,
  //       desktopRef: this.data?.desktopVideo?.refVideo?._ref
  //     });
  //     const response = await this.$sanity.fetch(query);
  //     this.mobileSrc = response.mobile?.asset.url;
  //     this.desktopSrc = response.desktop?.asset.url;
  //     this.sourceSrc = this.isMobile ? this.mobileSrc : this.desktopSrc;
  //   }
  // },
  async mounted() {
    this.isMobile = window.innerWidth <= 768;
    if (this.data.desktopVideo && this.data.mobileVideo) {
      const query = getHomeHeroVideos({
        mobileRef: this.data?.mobileVideo?.refVideo?._ref,
        desktopRef: this.data?.desktopVideo?.refVideo?._ref
      });
      const response = await this.$sanity.fetch(query);
      this.mobileSrc = response.mobile?.asset.url;
      this.desktopSrc = response.desktop?.asset.url;
      this.sourceSrc = this.isMobile ? this.mobileSrc : this.desktopSrc;
    }

    window.addEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 768;
      this.changeVideoSourceSrc();
    },
    changeVideoSourceSrc() {
      this.sourceSrc = this.isMobile ? this.mobileSrc : this.desktopSrc;
      const videoPlayer = this.$refs.videoPlayer;
      videoPlayer.load();
      videoPlayer.play();
    }
  }
};
