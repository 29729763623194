import { groq } from '@nuxtjs/sanity';

const getHomeHeroVideos = ({ mobileRef, desktopRef }) => groq`{
  "mobile": *[_id=="${mobileRef}"][0]{
    ...,
    "asset": upload.asset->
  },
  "desktop": *[_id=="${desktopRef}"][0]{
    ...,
    "asset": upload.asset->
  }
}`;

export default getHomeHeroVideos;
